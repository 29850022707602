window.Site = {

    liftoff : _ => {
        Site.Community.autoAdvance()
    },

    // AUto advance the community slider
    Community : {

        image : document.querySelector('.community img:not(hidden)'),

        advanceSlider : () => {

            //Grab the next or previous slide
            let next = Site.Community.image.nextElementSibling ?? Site.Community.image.parentNode.firstElementChild;

            Site.Community.image.setAttribute('hidden',null);
            next.removeAttribute('hidden')

            Site.Community.image = next;

            

        },

        autoAdvance : () => {
            setInterval(Site.Community.advanceSlider , 6000)
        }

    }

}; 

Site.liftoff();